
// Fonts
//@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

.breadcrumb {
    margin-bottom: 11px;
}

h1, .h1, h2, .h2, h3, .h3 {
    margin-top: 11px;
}

thead tr td {

  font-weight: bold;

}
